export function useModal(title: string | null, content: string | null, key: string | null = null) {
  if (content === null) {
    return
  }

  if (key !== null) {
    // Check if the key is already in the local storage
    if (localStorage.getItem(key) !== null) {
      return
    }

    // Save the key to the local storage
    localStorage.setItem(key, 'true')
  }

  // Emit show modal event
  window.dispatchEvent(
    new CustomEvent('showModal', {
      detail: {
        title: title ?? '',
        content: content ?? ''
      }
    })
  )
}
