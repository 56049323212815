<template>
  <div>
    <div class="flex items-end justify-center w-full bg-brand-tint-green">
      <img src="@/assets/images/quote.jpg" alt="" />
    </div>

    <div class="flex flex-col px-4 divide-x">
      <div class="flex flex-col gap-5 py-8">
        <h2>Offerte ontvangen?</h2>
        <p>Bedankt voor het samenstellen van jouw OPUS pod. Ontvang het aanbod op de mail.</p>
        <div class="flex flex-col gap-2">
          <div v-for="u in usps" :key="u" class="flex items-center gap-2">
            <IconCheck classes="text-brand-tint-green w-4 h-4" />
            <span class="text-brand-secondary">{{ u }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <button @click="modal = !modal" class="button-orange-contained">Ontvang offerte</button>
          <button @click="restart" class="button-white-contained">
            <span>Start een nieuwe configuratie</span>
          </button>
        </div>
      </div>

      <!-- Share -->
      <h4 class="text-brand-secondary">Deel jouw gemaakte 3D-configuratie</h4>
      <ShareBar />
    </div>

    <QuoteModal v-model="modal" />
  </div>
</template>

<script setup lang="ts">
import { onUnmounted, ref, watch } from 'vue';

import IconCheck from '@/components/Icons/IconCheck.vue'
import ShareBar from '@/components/ShareBar.vue'
import QuoteModal from '@/components/QuoteModal.vue'
import { usePodStore } from '@/stores/pod';
import { useGtm } from '@/composables/useGtm';

const pod = usePodStore()

const usps = [
  'Ontvang plattegronden en aanzichten',
  'Prijzen voor 30 dagen vast',
  'Vrijblijvend adviesgesprek',
  'Duidelijk prijsoverzicht'
]

function restart() {
  pod.setStep(0)
  pod.resetHash()
}

const modal = ref(false)

useGtm().addEvent('configurator-afgerond')

// watch for modal to open
watch(() => modal.value, (value) => {
  if (value) { // Pause the render
    if (window.pod.engine._activeRenderLoops.length > 0) {
      window.pod.engine.stopRenderLoop()
    }
  } else { // Resume the render
    if (window.pod.engine._activeRenderLoops.length === 0) {
      window.pod.render()
    }
  }
})

// Always render when component is unmounted
onUnmounted(() => {
  if (window.pod !== undefined && window.pod.engine !== undefined && window.pod.engine._activeRenderLoops.length === 0) {
    window.pod.render()
  }
})
</script>
