<template>
  <div v-show="open"
    class="fixed inset-0 z-20 flex w-full h-full lg:grid lg:place-items-center bg-brand-black/40 backdrop-blur">
    <div
      class="flex flex-col w-full max-w-2xl gap-4 p-4 overflow-y-auto bg-white max-h-[100dvh] lg:max-h-modal branded-scroll">
      <div v-if="!quoteRequested" class="flex flex-col gap-6 p-4">
        <div>
          <h3 class="text-brand-secondary">Ontvang een offerte</h3>
          <span>Vul hieronder je gegevens aan om een volledige geldige offerte te ontvangen</span>
        </div>
        <div id="quoteForm" ref="quoteForm"></div>
        <div class="text-center">
          <button @click="open = !open" class="text-sm font-medium">Nee bedankt, ik wil geen offerte</button>
        </div>
      </div>

      <div v-else class="flex flex-col gap-2 p-4 bg-brand-tint-lightgreen">
        <h2 class="pb-2">Kun je ons nog iets meer vertellen?</h2>

        <h3 class="pb-1">Hoe gaat de pod gebruikt worden?</h3>
        <div>
          <div class="grid grid-cols-2 pb-4 gap-x-2 gap-y-3 lg:gap-2">
            <label v-for="(o, idx) in options" :key="idx" class="checkbox">
              <div class="box"></div>
              <span>{{ o }}</span>
              <input type="checkbox" :value="o" v-model="pod.additionalInfo.purpose">
            </label>
          </div>

          <span class="text-sm italic">Deze informatie is uitsluitend voor intern gebruik. Dit help ons om jou goed te
            kunnen
            adviseren.</span>

        </div>

        <hr class="border-t-2" />

        <h3 class="pt-2 pb-1">Markeer de <u>exacte</u> positie van de pod</h3>
        <MapContainer />

        <div v-if="errors.length > 0">
          <span class="text-red-500">{{ errors }}</span>
        </div>
        <button @click="submitAdditionalInfo()" class="w-full mt-8 button-orange-contained">
          Aanvraag voltooien
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, watch, defineAsyncComponent } from 'vue'
import { usePodStore } from '@/stores/pod'
import { useHubspotForm } from '@/composables/useHubspotForm';
import { useGtm } from '@/composables/useGtm';

const MapContainer = defineAsyncComponent(() => import('@/components/MapContainer.vue'))

const pod = usePodStore()

const open = defineModel()

const quoteRequested = ref(false)

const quoteForm = ref()

const options = [
  'Werken',
  'Vrije tijd',
  'Sporten',
  'Overnachten',
  'Praktijkruimte',
  'Permanent verblijf'
]

const errors = ref('')

function submitAdditionalInfo() {
  if (pod.additionalInfo.lat === 0 || pod.additionalInfo.lng === 0) {
    errors.value = 'Selecteer een locatie op de kaart'
    return
  }

  pod.nextStep()
}

function hsEventListener(event: any) {
  if (event.data.id !== '0ee79628-af16-4456-881f-116aa8d5ed2a') {
    return;
  }

  if (event.data.type !== 'hsFormCallback') {
    return
  }

  if (event.data.eventName !== 'onFormSubmitted') {
    return
  }

  syncFormData(event.data.data)
}

function syncFormData(data: {
  submissionValues: {
    firstname: string
    lastname: string
    email: string
    company: string
    straatnaam: string
    huisnummer: string
    zip: string
    city: string
    phone: string
  }
}) {
  pod.quoteForm.voornaam = data.submissionValues.firstname
  pod.quoteForm.achternaam = data.submissionValues.lastname
  pod.quoteForm.email = data.submissionValues.email
  pod.quoteForm.bedrijf = data.submissionValues.company
  pod.quoteForm.straatnaam = data.submissionValues.straatnaam
  pod.quoteForm.huisnummer = data.submissionValues.huisnummer
  pod.quoteForm.postcode = data.submissionValues.zip
  pod.quoteForm.plaatsnaam = data.submissionValues.city
  pod.quoteForm.telefoon = data.submissionValues.phone

  quoteRequested.value = true

  pod.storeConfiguration()
}

function openForm() {
  setTimeout(() => {
    const event = new Event('change');

    let firstnameEl = quoteForm.value.querySelector('input[name=firstname]')
    firstnameEl.focus()
    firstnameEl.value = pod.optInForm.firstName
    firstnameEl.dispatchEvent(event)

    let lastnameEl = quoteForm.value.querySelector('input[name=lastname]')
    lastnameEl.focus()
    lastnameEl.value = pod.optInForm.lastName
    lastnameEl.dispatchEvent(event)

    let emailEl = quoteForm.value.querySelector('input[name=email]')
    emailEl.focus()
    emailEl.value = pod.optInForm.email
    emailEl.dispatchEvent(event)

    let telEl = quoteForm.value.querySelector('input[name=phone]')
    telEl.focus()
  }, 0)
}

const firstOpen = ref(true);

watch(open, (value) => {
  if (value && firstOpen.value) {
    openForm()
    firstOpen.value = false
  }
})

onMounted(async () => {
  await useHubspotForm().then(() => {
    window.hbspt.forms.create({
      region: "eu1",
      portalId: "25454358",
      formId: "0ee79628-af16-4456-881f-116aa8d5ed2a",
      target: '#quoteForm',
    });
  })

  // Add event listener to the form
  window.addEventListener('message', hsEventListener, false)

  useGtm().addEvent('configurator-open-offerte-formulier');
})

onBeforeUnmount(() => {
  window.removeEventListener('message', hsEventListener)
})
</script>
