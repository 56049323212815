<template>
    <div class="flex flex-col mt-3 rounded shadow">
        <!-- render -->
        <div class="flex gap-3 p-3 bg-brand-white">
            <button v-for="s in shareOptions" :key="s.label" @click="handleShare(s.label)"
                class="flex flex-col text-brand-primary gap-[6px] items-center w-full hover:scale-[103%] transition-transform">
                <component :is="s.icon" classes="w-6 h-6" />
                <span>{{ s.label }}</span>
            </button>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

import IconEmail from '@/components/Icons/IconEmail.vue';
import IconWhatsapp from '@/components/Icons/IconWhatsapp.vue';
import IconShare from '@/components/Icons/IconShare.vue';
import IconCheck from '@/components/Icons/IconCheck.vue'
import { usePodStore } from '@/stores/pod';
import { useGtm } from '@/composables/useGtm';

const pod = usePodStore();

const shareOptions = ref([
    { icon: IconEmail, label: 'E-mail' },
    { icon: IconWhatsapp, label: 'Whatsapp' },
    { icon: IconShare, label: 'Kopieer link' }
])

let isSharingInProgress = false;

function handleShare(label: string) {
    if (isSharingInProgress) {
        return;
    }

    useGtm().addEvent('configurator-share')

    const url = 'https://' + location.hostname + '/preview?pod=' + pod.hash;

    if (label === 'E-mail') {
        let mailto = "mailto:";
        let subject_cmd = "?subject=";
        let subject = "Bekijk mijn tuinkantoor in 3D | OPUS home office pods";
        let body_cmd = "&body=";
        let body = "Ik heb zojuist mijn eigen tuinkantoor ontworpen bij OPUS home office pods.%0a%0aBekijk het ontwerp in 3D:%0a" + encodeURIComponent(url);
        let open_url = mailto + subject_cmd + subject + body_cmd + body;
        window.open(open_url, "Email_window");
        return;
    }

    if (label === 'Whatsapp') {
        let message = "Ik heb zojuist mijn eigen tuinkantoor ontworpen bij OPUS home office pods.%0a%0aBekijk het ontwerp in 3D:%0a" + url;
        let whatsapp = "https://api.whatsapp.com/send?text=";
        let open_url = whatsapp + message;
        window.open(open_url, "WhatsApp_window");
        return;
    }


    if (label === 'Kopieer link') {
        if (navigator.share) {
            isSharingInProgress = true;
            navigator.share({
                title: 'Deel de 3D configuratie van jouw OPUS home office pod',
                url: url
            }).then(() => {
                isSharingInProgress = false;
            }).catch(() => {
                isSharingInProgress = false;
            })
        } else { // Browser does not support share-api. Fallback to copy link
            navigator.clipboard.writeText(url).then(
                () => {
                    // Change the icon of the button back to copy link after 3 seconds
                    // Change the icon in shareOptions const
                    shareOptions.value[2].icon = IconCheck;

                    setTimeout(() => {
                        shareOptions.value[2].icon = IconShare;
                    }, 3000);
                }
            );
        }
    }
}
</script>